import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'Herlan',
        description = "Le tournoi d'esport HerLan est une compétition exclusivement réservée aux femmes qui se déroule dans le domaine de l'esport. Il s'agit d'une initiative visant à promouvoir la participation des femmes dans ce secteur en encourageant leur participation active dans des événements de compétition.";
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/Affiches/affiche1.png" alt="About Images"/>

                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                    <br></br>

                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                       <div >
                                            <div className="about-us-list">
                                                <h3 className="title">Es-tu prête pour la seconde édition qui réunit la scène féminine française sur Valorant ?
</h3>
                                                <p> On t'invite à la 2ème édition de la Herlan Series, le tournoi esport pour les joueuses, la seconde édition se fera sur Valorant.
<br></br>
<div> Constitue une équipe de 5 joueuses et inscris-toi ! </div>
<br></br>
<div>  Attention, les joueuses doivent créer leur compte avec leur équipe au complet. </div>
<br></br>
Les 24 équipes inscrites participeront à un tournoi en ligne pour décrocher leur place en finale. 

<div> Les 4 meilleures équipes seront ensuite invitées au Festival Tech and Gaming les 19 et 20 octobre 2024 au Palais Omnisports de Thiais pour un tournoi offline. (frais de déplacement et d'hébergement non pris en charge).<br></br></div>


<br></br>

À gagner : Cash prizes et de nombreux lots pour les équipes gagnantes ! </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                       <div >
                                            <div className="about-us-list">
                                                <h3 className="title">Coéquipières</h3>
                                                <p>Si tu cherches des coéquipières pour former une équipe, rends-toi sur le Discord Herlan ( https://discord.gg/XrKPdgRF6V ) dans les canaux de discussion dédiés aux recherches de team !
</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                       <div >
                                            <div className="about-us-list">
                                                <h3 className="title">Format</h3>
                                                <p>Herlan est en un tournoi à Double élimination</p>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt--30 mt_sm--10">
                                       <div >
                                            <div className="about-us-list">
                                                <h3 className="title">Prix</h3>
                                                <p>Les récompenses suivantes seront attribuées à l'issue de la phase finale :<br></br>
2 000€ de cash prize à se partager entre les équipes gagnantes.
</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;

/*
                                       <div className="col-lg-6 col-md-12 col-sm-12 col-12">

*/